import * as React from "react";
import "../../gatsby-browser.js";
import Into from "../static/img/intro.png";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import HowAbout from "../component/Howwedo/HowAbout";
import HowTech from "../component/Howwedo/HowTech";
import { motion } from "framer-motion";
import axios from "axios";
import Load from "../static/img/load/load.gif";
import { url } from "../url/url";
import HowMethod from "../component/Howwedo/HowMethod";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MetaTags from "../component/MetaTags.jsx";

class RateUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      review: {
        username: "",
        course: "",
        comment: "",
        rating: "",
        email:""
      },
    };
  }

  handleInputChange = (fieldName, value) => {
    // console.log("value", value);
    this.setState((prevState) => ({
      review: {
        ...prevState.review,
        [fieldName]: value,
      },
    }));
  };

  // getComment = (e) => {
  //     this.setState.review({
  //         comment: e.target.value
  //     })
  // }

  // getCourse = (e) => {
  //     this.setState.review({
  //         rating: e.target.value
  //     })
  // }

  // getRating = (e) => {
  //     this.setState.review({
  //         course: e.target.value
  //     })
  // }

  handleSubmit = async() => {
    const { username, comment, course,rating,email } = this.state.review;
  
    if (!username) {
      NotificationManager.error("Name cannot be empty");
    } else if (!comment) {
      NotificationManager.error("Comment cannot be empty");
    } else if (!rating) {
      NotificationManager.error("Rating cannot be empty");
    } else if (!course) {
      NotificationManager.error("Course cannot be empty");
    } else if (!email) {
      NotificationManager.error("Email cannot be empty");
    }
    
    else {
        try {
            const response = await axios({
              method:'POST',
              url:`${url}/review/`,
              data: this.state.review
            }).then((result)=> {
                // console.log(result)
                if(result.status ===200) {
                  this.setState({
                    review: {
                      username:"",
                      email:"",
                      course:"",
                      comment:"",
                      rating:""
                    }
                  })
                  NotificationManager.success('Successfully Submitted..!!')
                };
            });
            // console.log("Data posted:", response.data);
          } catch (error) {
            console.error("Error posting data:", error);
          }
    }
  };
  
  showAlert(message) {
    alert(message);
  }



  render() {
    // console.log("from state", this.state.review);
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { course, username,rating,comment,email } = this.state.review;

    return (
      <>
        <Navbar />

        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="howwedo" className="howwedo rate-us">
          </section>

          <div className={"row"}>
            <div className={"col-sm-12 contact-form"}>
            <div align="center">
              <h1 className="rate-heading">
                Rate Us
              </h1>
            </div>
              <br />

              <div className="row">
                <div className={"col-md-6"}>
                  <label>Full Name</label>
                  <input
                    className={"form-control input-fields"}
                    placeholder={"Enter your name"}
                    value={username}
                    onChange={(e) =>
                      this.handleInputChange("username", e.target.value)
                    }
                  />{" "}
                  <br />
                </div>
                <div className={"col-md-6"}>
                  <label>Rating (Out of 5)</label>
                  <input
                    type={"number"}
                    value={rating}
                    min={1}
                    max={5}
                    className={"form-control input-fields"}
                    placeholder={"Enter rating"}
                    onChange={(e) =>
                      this.handleInputChange("rating", e.target.value)
                    }
                  />
                </div>
                <div className={"col-md-6"}>
                  <label>Email</label>
                  <input
                    type={"email"}
                    value={email}
                    className={"form-control input-fields"}
                    placeholder={"Enter your email"}
                    onChange={(e) =>
                      this.handleInputChange("email", e.target.value)
                    }
                  />
                </div>
                <div className={"col-md-6"}>
                  <label>Course</label>
                  <select
                    username="course"
                    id="course"
                    className="input-fields"
                    value={course}
                    onChange={(e) =>
                      this.handleInputChange("course", e.target.value)
                    }
                  >
                    <option defaultChecked value="">
                      Select your course
                    </option>
                    <option value="jlpt n4">JLPT N4</option>
                    <option value="jlpt n5">JLPT N5</option>
                    <option value="python">Python</option>
                    <option value="django">Django</option>
                    <option value="react">React</option>
                  </select>
                </div>
                <div className={"col-sm-12"}>
                  <br />
                  <label>Comment</label>
                  <textarea
                    rows={"8"}
                    value={comment}
                    maxLength={250}
                    className={"form-control input-fields"}
                    placeholder={"comment"}
                    onChange={(e) =>
                      this.handleInputChange("comment", e.target.value)
                    }
                  />
                </div>
              </div>

              <br />

              <div align="center">
                <button className={" btn btn-send "} onClick={this.handleSubmit}>
                  Done
                </button>
              </div>
            </div>
          </div>

          <Footer />
        </motion.div>

        <NotificationContainer />
        <MetaTags title={"Rate Us | Koderbox"} />
      </>
    );
  }
}

export default RateUs;
